import React from 'react';
import _ from 'lodash';
import Link from 'components/base/Link';
import classnames from 'classnames';
import { slug } from 'components/utils/helpers';
import { cleanupValues } from 'components/utils/helpers';

const CollectionIndex = ({ data, items, className = '' }) => {
  data = data.sort((a, b) => a.name.localeCompare(b.name));
  const mediums = data.filter(item => item.name.split('__').length < 2);
  // Construct the object to draw categories on page
  // [
  //   {
  //     value: "something"
  //     children: [
  //       {
  //         value: "something__whatever"
  //         children: [
  //           {
  //             value: "something__whatever__somethingElse"
  //             children: []
  //           }
  //         ]
  //       }
  //     ]
  //     values: ["something__value"...]
  //     secondaryValues: ["something__value__whateverelse"]
  //     tertiaryValues: ["something__value__whateverelse__mything"]
  //   }
  // ]
  let taxaObj = [];
  mediums.forEach(medium => {
    let obj = { value: medium.name, slug: medium.slug, children: [] };
    let types = data.filter(type => {
      return (
        type.name.split('__').length === 2 &&
        type.name.indexOf(medium.name) === 0
      );
    });
    types.forEach(type => {
      let typeObj = { value: type.name, slug: type.slug, children: [] };
      obj.children.push(typeObj);
      let secondaryTypes = data.filter(secondaryType => {
        return (
          secondaryType.name.split('__').length === 3 &&
          secondaryType.name.indexOf(type.name) === 0
        );
      });
      secondaryTypes.forEach(secondaryType => {
        let secondaryTypeObj = {
          value: secondaryType.name,
          slug: secondaryType.slug,
          children: []
        };
        typeObj.children.push(secondaryTypeObj);
        let tertiaryTypes = data.filter(tertiaryType => {
          return (
            tertiaryType.name.split('__').length === 4 &&
            tertiaryType.name.indexOf(secondaryType.name) === 0
          );
        });
        tertiaryTypes.forEach(tertiaryType => {
          let tertiaryTypeObj = {
            value: tertiaryType.name,
            slug: tertiaryType.slug
          };
          secondaryTypeObj.children.push(tertiaryTypeObj);
        });
      });
    });
    taxaObj.push(obj);
  });
  const toggleAccordion = e => {
    e.target.closest('.Accordion').classList.toggle('Accordion--collapse');
  };
  // We're going to go through the item values and create objects that look like the following:
  // [
  //   {
  //     name: "Type",
  //     count: 123,
  //     types: [
  //       {
  //         name: "Manual",
  //         count: 12
  //       }
  //     ]
  //   }
  // ]
  let malValues = [];
  items.forEach(item => {
    item.values.forEach(value => {
      // value has a name and data (both strings)
      // want to check if data exists
      if (value.data.length > 0) {
        // then we want to check if an object with this name
        // exists inside of malValues
        let foundValueIndex = _.findIndex(malValues, ['name', value.name]);
        // if the object with this name already exists
        if (foundValueIndex !== -1) {
          let obj = malValues[foundValueIndex];
          // update the object,
          // increase the count of times seen
          obj.count += 1;
          let foundTypeIndex = _.findIndex(obj.types, ['name', value.data]);
          if (foundTypeIndex !== -1) {
            obj.types[foundTypeIndex].count += 1;
          } else {
            obj.types.push({
              name: value.data.replace(/[^\x00-\x7F]/g, '').replace(/"/g, ''), // eslint-disable-line
              count: 1
            });
          }
          malValues[foundValueIndex] = obj;
        } else {
          // otherwise create a new object
          let obj = {
            name: value.name.replace(/[^\x00-\x7F]/g, '').replace(/"/g, ''), // eslint-disable-line
            count: 1,
            types: [
              {
                name: value.data.replace(/[^\x00-\x7F]/g, '').replace(/"/g, ''), // eslint-disable-line
                count: 1
              }
            ]
          };
          malValues.push(obj);
        }
      }
    });
  });
  malValues = cleanupValues(malValues);
  return (
    <article
      className={`${className} ph3 ph4-xl pv3 pv4-xl container container--thin container--boxed`}
    >
      <section className="flex flex-wrap">
        {taxaObj.map((item, index) => {
          return (
            <ul
              key={index}
              className={classnames(
                'w-100 list mt4 mt0-md body-copy Accordion',
                {
                  'Accordion--collapse': index !== 0,
                  'mt4-md': index >= 4
                }
              )}
            >
              <button
                className={classnames(
                  'Accordion__button CollectionIndex__list-header flex items-center tl',
                  {
                    'mt3 mt4-md': index !== 0
                  }
                )}
                onClick={toggleAccordion}
              >
                <span className="mr2 ttu">
                  {item.value.split('__')[item.value.split('__').length - 1]}
                </span>
                <div className="Accordion__arrow flex justify-between items-center">
                  <span className="db"></span>
                  <span className="db"></span>
                </div>
              </button>
              <li className="Accordion__body">
                <ul>
                  {item.children.map((type, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/collection/${type.slug}`}
                          className="db mt3 bn CollectionIndex__list-header"
                        >
                          {
                            type.value.split('__')[
                              type.value.split('__').length - 1
                            ]
                          }
                        </Link>
                        <ul className="pl1 pl3-md flex flex-wrap mt1 mt3-md">
                          {type.children.map((secondaryType, index) => {
                            return (
                              <li key={index} className="w-25-md mb3">
                                <Link
                                  to={`/collection/${secondaryType.slug}`}
                                  className="db mt3 bn CollectionIndex__list-header"
                                >
                                  {
                                    secondaryType.value.split('__')[
                                      secondaryType.value.split('__').length - 1
                                    ]
                                  }
                                </Link>
                                <ul className="mt1 pl1 pl3-md">
                                  {secondaryType.children.map(
                                    (tertiaryType, index) => {
                                      return (
                                        <li key={index}>
                                          <Link
                                            to={`/collection/${tertiaryType.slug}`}
                                            className="bn"
                                          >
                                            {
                                              tertiaryType.value.split('__')[
                                                tertiaryType.value.split('__')
                                                  .length - 1
                                              ]
                                            }
                                          </Link>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          );
        })}
      </section>
      <section className="mt4 mt6-md flex flex-wrap">
        {malValues.map((value, index) => {
          return (
            <ul
              key={index}
              className={classnames(
                'w-100 w-25-md list mt4 mt0-md body-copy Accordion Accordion--collapse',
                {
                  'mt4-md': index >= 4
                }
              )}
            >
              <div className="CollectionIndex__list-header flex tl">
                <span className="mr2">{value.name}</span>
              </div>
              <li className="mt3">
                <ul className="pl1 pl3-md mb3">
                  {value.types.map((item, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/${slug(value.name)}/${slug(item.name)}`}
                          className="bn"
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          );
        })}
      </section>
    </article>
  );
};

export default CollectionIndex;
