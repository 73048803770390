import React from 'react';

const IconClose = ({ className = '' }) => {
  return (
    <svg className={className} width="24px" height="23px" viewBox="0 0 24 23">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-241.000000, -230.000000)"
          stroke="#000000"
          strokeWidth="0.5625"
        >
          <g transform="translate(241.875000, 230.625000)">
            <line
              x1="12.0236538"
              y1="10.1173269"
              x2="22.0384615"
              y2="0.101942308"
            ></line>
            <line
              x1="0.102115385"
              y1="22.0389231"
              x2="10.1175"
              y2="12.0235385"
            ></line>
            <line
              x1="12.0236538"
              y1="12.0233077"
              x2="22.0384615"
              y2="22.0386923"
            ></line>
            <line
              x1="0.102115385"
              y1="0.101711538"
              x2="10.1175"
              y2="10.1170962"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconClose;
