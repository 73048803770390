import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Link from 'components/base/Link';
import CollectionIndex from 'components/modules/CollectionIndex';
import IconSearch from 'svg/icn-search';
import IconClose from 'svg/icn-close';

const Indexed = ({ data }) => {
  const taxa = data.allMalTaxa.nodes.sort((a, b) => {
    return b.childTaxa.length - a.childTaxa.length;
  });
  const items = data.allMalItem.nodes;
  const [closeNavTo, setCloseNavTo] = useState('/collection');
  const [fromTaxon, setFromTaxon] = useState(false);
  useEffect(() => {
    setCloseNavTo(
      window.history.state && window.history.state.fromTaxon
        ? `/${window.history.state.fromTaxon}`
        : '/collection'
    );
    setFromTaxon(
      window.history.state && window.history.state.fromTaxon
        ? window.history.state.fromTaxon
        : false
    );
  }, []);
  return (
    <Layout pageTitle="Catalog">
      <article className="CollectionTabs container container--thin container--boxed body-copy-only CollectionTabs--index-active">
        <ul className="block flex">
          <Link
            className="CollectionTabs__search db w-100 w-50-md br pointer tc pv2 relative body-subheader"
            to="/collection/search"
            shouldAnimate={false}
            fromTaxon={fromTaxon}
          >
            <IconClose className="CollectionTabs__close-search absolute" />
            <IconSearch className="CollectionTabs__search-title" />
          </Link>
          <Link
            className="CollectionTabs__index w-100 w-50-md pointer tc pv2 relative body-subheader"
            to={closeNavTo}
            shouldAnimate={false}
          >
            <IconClose className="CollectionTabs__close-index absolute" />
            <span className="CollectionTabs__index-title">Index</span>
          </Link>
        </ul>
      </article>
      {/* The index */}
      <CollectionIndex
        className="CollectionIndex mb7"
        data={taxa}
        items={items}
      />
    </Layout>
  );
};

export default Indexed;

// Should return one item (with images) for each mal taxa
// We used to filter this based off of taxas that had no parent
// (so, root taxa) but latest API changes 4/27/2020
// have made it so that all parent ids are now null
export const query = graphql`
  query indexedTaxaAndItems {
    allMalTaxa {
      nodes {
        name
        slug
        childTaxa {
          id
          name
          slug
        }
      }
    }
    allMalItem {
      nodes {
        _360_images {
          url
        }
        images {
          url
        }
        values {
          name
          data
        }
        name
        slug
        accessionNumber
      }
    }
  }
`;
